<template>
  <div v-if="feed.length > 0 && feedavail">
    <div
      style="transition: height .1s linear; "
      v-for="item in feedByLang"
      :key="item.id"
      class="d-flex flex-column listitem rounded-lg mb-2"
    >
      <v-img
        v-if="item.type === 'article' && item.cover"
        @click="handleclickaction(item)"
        class="rounded-t ma-0 pa-0"
        :lazy-src="
          item.cover
            ? $root.asseturl + item.cover.private_hash
            : '/media/bg/doctor-bg.jpeg'
        "
        :src="
          item.cover
            ? $root.asseturl + item.cover.private_hash
            : '/media/bg/doctor-bg.jpeg'
        "
        height="170px"
      />
      <div
        @click="handleclickaction(item)"
        style="padding:10px;width:100%;"
        class="d-flex flex-column"
      >
        <div
          style="font-size:1.4rem"
          class="primary--text font-weight-bold  "
          :class="
            selecteditem && selecteditem.id === item.id ? '' : 'text-truncate'
          "
        >
          {{ item.content.title }}
        </div>
        <div class="mb-2">
          {{
            item.created_on
              | moment("timezone", $util.getTimezone(), "from", "now")
          }}
        </div>
        <div v-if="item.type === 'article'">
          {{ $util.truncate(item.content.summery, 150) }}
        </div>
        <template v-else>
          <div v-if="selecteditem != null">
            {{
              selecteditem.id !== item.id
                ? $util.truncate(item.content.summery, 150)
                : item.content.summery
            }}
          </div>
          <div v-else>{{ $util.truncate(item.content.summery, 150) }}</div>
        </template>
      </div>
      <template
        v-if="item.type === 'advice' && item.content.summery.length > 150"
      >
        <div
          v-if="selecteditem == null || selecteditem.id != item.id"
          style="height: 30px;margin-top: -30px;"
          @click="handleclickaction(item)"
          class="showmore d-flex align-end justify-center primary--text"
        >
          <div>
            <v-icon color="primary" class="pushx" small
              >mdi-chevron-down</v-icon
            >
            {{ $t("FEED.showmore") }}
          </div>
        </div>
      </template>
      <v-divider class="ma-0 pa-0" />

      <v-row class="ma-0 pa-0" style="padding:10px;width:100%; ">
        <v-col
          style="color:#757575"
          cols="9"
          class="d-flex align-center ma-1 pa-1"
        >
          <v-avatar class="mx-2" size="20" color="primary">
            <v-img
              v-if="item.owner && item.owner.avatar != null"
              :lazy-src="$util.asseturl + item.owner.avatar.private_hash"
              :src="$util.asseturl + item.owner.avatar.private_hash"
            ></v-img>
            <div
              v-else
              style="text-transform: uppercase; width: 100%"
              class="white--text"
            >
              {{ item.owner ? item.owner.first_name.charAt(0) : "D" }}
            </div>
          </v-avatar>
          <template v-if="item.owner && $route.name != 'DoctorProfile'">
            {{
              $t("FEED.by", {
                type:
                  item.type === "advice"
                    ? $t("FEED.advice")
                    : $t("FEED.article")
              })
            }}
            <router-link
              :to="'/d/' + item.owner.external_id"
              v-slot="{ href, navigate }"
            >
              <a class="px-1" :href="href" @click="navigate">
                {{ `${item.owner.first_name} ${item.owner.last_name}` }}
              </a>
            </router-link>
          </template>
          <span v-else class="px-1 font-weight-medium">{{
            item.owner
              ? $t("FEED.by", {
                  type:
                    item.type === "advice"
                      ? $t("FEED.advice")
                      : $t("FEED.article")
                }) + ` ${item.owner.first_name} ${item.owner.last_name}`
              : "Doctory Doctor"
          }}</span>
        </v-col>

        <v-col style="color:#757575" class="ma-1 pa-1">
          <div @click="likeaction(item)">
            <v-icon v-if="item.like" size="18" class="mx-1" color="red"
              >mdi-heart</v-icon
            >
            <v-icon v-else size="18" class="mx-1" color="red"
              >mdi-heart-outline</v-icon
            >
            <span v-if="item.likes">{{ item.likes }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-bottom-sheet
      :max-width="$vuetify.breakpoint.mobile ? '' : '50vw'"
      style="z-index: 1000"
      scrollable
      v-model="showfeeddetails"
    >
      <v-card v-if="selecteditem" class="" elevation="0">
        <v-app-bar
          v-if="selecteditem.cover"
          absolute
          elevation="0"
          class="white--text px-4 transparent"
        >
          <v-toolbar-title>
            {{ selecteditem.content.title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon dark color="white" @click="showfeeddetails = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-app-bar v-else elevation="0" class="primary--text px-4 mb-2 white">
          <v-toolbar-title>
            {{ selecteditem.content.title }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            color="primary"
            @click="
              showfeeddetails = false;
              selecteditem = null;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-img
          :gradient="`to bottom , #0971ce , rgba(25,32,72,.5)`"
          v-if="selecteditem.cover"
          :height="$vuetify.breakpoint.mobile ? '140' : '200'"
          class="rounded-t mb-2"
          :src="$root.asseturl + selecteditem.cover.private_hash"
        ></v-img>
        <div class="d-flex justify-space-between align-center px-4 mb-4">
          <div>
            <v-avatar class="mx-2" size="20" color="primary">
              <v-img
                :lazy-src="
                  $util.asseturl + selecteditem.owner.avatar.private_hash
                "
                v-if="selecteditem.owner && selecteditem.owner.avatar != null"
                :src="$util.asseturl + selecteditem.owner.avatar.private_hash"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase; width: 100%"
                class="white--text"
              >
                {{
                  selecteditem.owner
                    ? selecteditem.owner.first_name.charAt(0)
                    : "D"
                }}
              </div>
            </v-avatar>
            <span class="font-weight-medium">{{
              selecteditem.owner
                ? $t("GENERAL.doctor", {
                    name: `${selecteditem.owner.first_name}  ${selecteditem.owner.last_name}`
                  })
                : "Doctory Doctor"
            }}</span>
          </div>
          <div @click="likeaction(selecteditem)">
            <v-icon v-if="selecteditem.like" size="18" class="mx-1" color="red"
              >mdi-heart</v-icon
            >
            <v-icon v-else size="18" class="mx-1" color="red"
              >mdi-heart-outline</v-icon
            >
            <span v-if="selecteditem.likes">{{ selecteditem.likes }}</span>
          </div>
        </div>
        <v-card-text
          style="font-size:1.2rem"
          v-html="selecteditem.content.content"
        >
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
  <div
    v-else-if="feed.length == 0 && feedavail"
    style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: calc(100vh - 56px);
            padding-bottom: 156px;
          "
  >
    <v-progress-circular indeterminate size="30" color="primary" class="mb-3" />
    {{ $t("FEED.loading") }}
  </div>
  <div v-else class="text-center my-10">
    {{
      owner
        ? $t("FEED.nofeedname", { name: owner.first_name })
        : $t("FEED.nofeed")
    }}
  </div>
</template>
<script>
export default {
  name: "Feed",
  components: {},
  props: ["owner"],
  data() {
    return {
      feed: [],
      selecteditem: null,
      showfeeddetails: false,
      feedavail: true
    };
  },
  computed: {
    feedByLang() {
      return this.feed
        .filter(item => {
          return (
            item.translation.findIndex(t => {
              return t.lang == this.$root.lang;
            }) != -1
          );
        })
        .map(item => {
          item.content = item.translation.find(trans => {
            return trans.lang == this.$root.lang;
          });
          return item;
        });
    }
  },
  mounted() {
    this.getFeed();
    this.$util.EventBus.$on("updatefeedpage", () => {
      this.$forceUpdate();
    });
    console.log("this.$route.name", this.$route.name);
  },
  destroyed() {},
  methods: {
    handleclickaction(item) {
      if (item.type === "advice") {
        if (this.selecteditem && this.selecteditem.id === item.id)
          this.selecteditem = null;
        else this.selecteditem = item;
      } else {
        this.selecteditem = item;
        this.showfeeddetails = true;
      }
    },
    likeaction(i) {
      this.$feed.likeAction(i);
      if (i.like) {
        i.like = false;
        i.likes--;
      } else {
        i.like = true;
        i.likes++;
      }
      this.$forceUpdate();
    },
    getFeed() {
      console.log("owner", this.owner);
      this.$feed.getFeedItems(this.owner ? this.owner.id : null).then(data => {
        this.feed = data;
        // console.log("feed", data);
        if (data.length === 0) this.feedavail = false;
      });
    }
  }
};
</script>
